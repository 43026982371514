import logo from './assets/images/nav/header.png';
// import './App.css';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import './styles/index.css'
import activeImage from './assets/images/active.png';
import React, { useEffect, useState } from 'react';
function App() {
  const [scrollY, setScrollY] = useState(0);
  const [topId, setTopId] = useState('a1');
  const [pageImg, setpageImg] = useState(require('./assets/images/app/page_pc.png'))
  // 展开收起
  const [isExpanded, setIsExpanded] = useState(false);
  // 菜单
  const menuItems = [
    { id: 'a1', label: '关于鼓电', href: '#about' },
    { id: 'a6', label: 'APP下载', href: '#appdownload' },
    { id: 'a2', label: '鼓电创始人', href: '#founder' },
    { id: 'a3', label: '老师介绍', href: '#teacher' },
    { id: 'a4', label: '学员风采', href: '#student' },
    { id: 'a5', label: '寻找鼓电', href: '#map' },
  ];
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const updateFontSize = () => {
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    // console.log(htmlWidth);  
    if (htmlWidth <= 375) {
      htmlWidth = 375
    }
    if (htmlWidth > 750) {
      setIsExpanded(true)
      setpageImg(require('./assets/images/app/page_pc.png'))
    } else {
      setIsExpanded(false)
      setpageImg(require('./assets/images/app/page_moblie.png'))
    }
    document.documentElement.style.fontSize = `${htmlWidth / 10}px`;
    console.log('document.documentElement.style.fontSize:', document.documentElement.style.fontSize)
  };
  // 点击下载
  const handleDownload = (type) => {  
    if(type == 'android'){
      const url = 'https://drumstudio.com.cn/appfile/android/鼓电v1.0.2.apk';  
      const link = document.createElement('a');  
      link.href = url;  
      link.setAttribute('download', '鼓电v1.0.2.apk'); // Specify the filename for download  
      document.body.appendChild(link);  
      link.click();  
      document.body.removeChild(link); 
    }else{

    }
  };
  useEffect(() => {

    // swiper
    const mySwiper = new Swiper('.swiper', {
      autoplay: true,
      delay: 3000, // Set autoplay delay  
      slidesPerView: 'auto',
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        // bulletClass : 'my-bullet',
      },
      // loop:true,
    });

    mySwiper.slideNext();
    const handleScroll = () => {
      const scrollTop = window.scrollY
      setScrollY(window.scrollY);
      // console.log('Scroll position:', scrollTop);
      //获取滚动条到顶部的距离
      // let scrollTop = document.getElementById('box').scrollTop
      // 获取右侧每个盒子距离顶部的高度
      let height1 = document.getElementById('about').offsetTop
      let height2 = document.getElementById('appdownload').offsetTop
      let height3 = document.getElementById('founder').offsetTop
      let height4 = document.getElementById('teacher').offsetTop
      let height5 = document.getElementById('student').offsetTop
      let height6 = document.getElementById('map').offsetTop
      if (scrollTop >= height1 && scrollTop < 600) {
        setTopId('a1')
      } else if (scrollTop > 600 && scrollTop <= height2) {
        setTopId('a6')
      } else if (scrollTop > height2 && scrollTop <= height3) {
        setTopId('a2')
      } else if (scrollTop > height3 && scrollTop <= height4) {
        setTopId('a3')
      } else if (scrollTop > height4 && scrollTop <= height5) {
        setTopId('a4')
      } else if (scrollTop > height5) {
        setTopId('a5')
      }
    };
    // 添加滚动事件监听器  
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateFontSize);
    updateFontSize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateFontSize);
      mySwiper.slideNext();
    };
  }, []);
  const founderImg = [
    require('./assets/images/founder/1.jpg'),
    require('./assets/images/founder/2.jpg'),
    require('./assets/images/founder/3.jpg'),
    require('./assets/images/founder/4.jpg'),
    require('./assets/images/founder/5.jpg'),
  ];
  const teacherImg = [
    {
      url: require('./assets/images/teacher/1.jpg'),
      name: '张老师',
      text: '十方音乐 DRUM STUDIO 创始人／资深鼓教师',
    },
    {
      url: require('./assets/images/teacher/2.jpg'),
      name: '李老师',
      text: '十方音乐 DRUM STUDIO 创始人／资深鼓教师',
    },
    {
      url: require('./assets/images/teacher/3.jpg'),
      name: '王老师',
      text: '十方音乐 DRUM STUDIO 创始人／资深鼓教师',
    }
  ]
  const mapImg = [
    {
      url: require('./assets/images/map/map1.png'),
      name: '鼓电studio'
    },
    {
      url: require('./assets/images/map/map2.png'),
      name: '鼓电club'
    },
    {
      url: require('./assets/images/map/map3.png'),
      name: '鼓电beats'
    },

  ]
  return (
    <div className="App">
      <header className="App-header" id='about'>
        <img src={logo} className="App-logo" alt="logo" />
        <div className='title'>
          <p>I'm a  </p>
          <p>DRUMMER</p>
          <p>欢迎加入<span>鼓电</span></p>
        </div>
        <div className='moblie_logo'><img src={require('./assets/images/logo.png')} alt='鼓电' className='logo' /></div>
        <div className={`nav_title ${scrollY > 200 ? "topshow" : ""}`}>
          <img src={require('./assets/images/logo.png')} alt='鼓电' className='logo' />
          <div className='center'>
            {menuItems.map(item => (
              <a href={item.href} key={item.id} className={`li ${topId === item.id ? 'active' : ''}`}>
                <a>{item.label}</a>
                <img src={activeImage} alt='选中' className='jianjian' />
              </a>
            ))}
          </div>
        </div>
      </header>
      {/* 介绍 */}
      <div className='Introduction'>
        <div className='underlined_Title'><span className='lined-text'>关于鼓电</span></div>
        <p>【鼓電】打击乐俱乐部，成立于2009年，致力于青少年架子鼓演奏技巧能力培养及现代流行音乐素养教育，近十年的发展已成为海淀区非常优秀的专业权威架子鼓爵士鼓培训基地。为各大中学输送了很多优秀架子鼓特长生，学员毕业逾百人。因专注于架子鼓演奏基本功训练及学院派的教学模式，多年来，多名学员多次获得海淀区艺术节小学组中学组一二三等奖，取得架子鼓八级及以上级别超过半数。</p>
        {isExpanded && (
          <p className='content_btn'>
            近两年，为了提供学员更丰富的表演机会和舞台经验，每年鼓電都会举办两次以上大型演出，也吸引了大批粉丝，得到了家长和学员的一致认可。如果作为家长，您正在徘徊于各种艺术培训学校，如果您的孩子总是对大众乐器没有兴趣，如果您已经工作仍对架子鼓情有独钟，那么欢迎现在就加入我们！
          </p>
        )}
        <div className='btn' onClick={handleToggle}>
          <span>
            {isExpanded ? '收起' : '展开全部'}
            {
              !isExpanded && <img src={require('./assets/images/icon/down_small.png')} alt="Icon" />
            }
          </span>
        </div>
      </div>
      {/* app下载 */}
      <span id='appdownload'></span>
      <div className='appDownload'>
        <div className='underlined_Title'><span className='lined-text'>APP下载</span></div>
        <div className='appContent'>
          <img src={pageImg} alt='手机页面' />
          <div className='download_app'>
            <div className='applogo'>
              <img src={require('./assets/images/app/app_logo.png')} alt='手机app' />
              <span>鼓电</span>
            </div>
            <div className='ios'onClick={()=>handleDownload('ios')}>
              <img src={require('./assets/images/app/ios.png')} alt='苹果下载' />
              <span>ios版下载</span>
            </div>
            <div className='android' onClick={()=>handleDownload('android')} >
              <img src={require('./assets/images/app/android.png')} alt='安卓下载' />
              <span>Android版下载</span>
            </div>
          </div>
        </div>
      </div>
      {/* 创始人展示 */}
      <span id='founder'></span>
      <div className='section'>
        <div className='underlined_Title'><span className='lined-text'>鼓电创始人</span></div>
        <div className='myswiper'>
          <div className="swiper">
            <div className="swiper-wrapper">
              {
                founderImg.map((value, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <div className='item'>
                        <img src={value} alt='name' />
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
        <div id="teacher"></div>
        <div className='underlined_Title'><span className='lined-text'>老师介绍</span></div>
        <div className='list_item'>
          <div className='scroll_item'>
            {
              teacherImg.map((item, index) => {
                return (
                  <div className='list_item_div' key={index}>
                    <div className='list_item_img'>
                      <img src={item.url} alt={item.name} />
                    </div>
                    <p>{item.name}</p>
                    <p className='list_item_text_1'>{item.text}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      {/* 学生展示 */}
      <div id='student'></div>
      <div className='studentShow'>
        <div className='underlined_Title'><span className='lined-text'>学员风采</span></div>
        <div className='show'>
          <img src={require('./assets/images/student/student.png')} alt='学生' />
        </div>
      </div>
      {/* 地图 */}
      <span id='map'></span>
      <div className='mapList'>
        <div className='underlined_Title'><span className='lined-text'>寻找鼓电</span></div>
        <div className='map_div'>
          {
            mapImg.map((value, index) => {
              return (
                <div className='map' key={index}>
                  <img src={value.url} alt={value.name} />
                  <p>{value.name}</p>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className='footer'>

      </div>
    </div>
  );
}

export default App;
